@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.wrap {
  width: 100%;
  border-radius: var(--border-radius-medium);
  position: relative;
  height: 660px;
  padding-bottom: 0;

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .top {
    padding: 8px 40px;
    border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
    background-color: #edf2f6;
    width: 100%;
    height: 294px;
    background-repeat: no-repeat, no-repeat;
    background-image: url("/images/pictures/timer-clock.webp"), url("/images/pictures/lines.webp");
    background-position:
      0% 20%,
      -5% center;
    background-size:
      213px 200px,
      472px 350px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .title {
      @include ff(24px, 500, 40px);
      text-transform: uppercase;
      letter-spacing: 0.05em;
      width: fit-content;
      white-space: nowrap;
      margin-left: 245px;
      margin-top: 90px;

      @media screen and (max-width: 1439px) {
        display: none;
      }
    }

    .timerPlaceholder {
      height: 80px;
      width: 100%;
      background-color: transparent;
    }

    .timer {
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 16px;
      margin-top: 16px;
      flex-shrink: 0;

      @media screen and (max-width: 1439px) {
        position: absolute;
        top: 0;
        right: 22px;
      }

      @media screen and (max-width: 767px) {
        gap: 10px;
        margin-top: 0;
        top: 12px;
        right: 9px;
      }

      &TextContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 767px) {
          justify-content: end;
        }
      }

      &Text {
        @include font18;
        font-weight: 500 !important;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      &Title {
        opacity: 0;

        @media screen and (max-width: 1439px) {
          opacity: 1;
          @include font24;
        }

        @media screen and (max-width: 767px) {
          @include font16;
          line-height: 20px !important;
        }
      }
    }

    @media screen and (max-width: 1439px) {
      background-position:
        -7% 20%,
        -5% center;
    }

    @media screen and (max-width: 1023px) {
      padding: 28px 22px;
    }

    @media screen and (max-width: 767px) {
      background-size:
        94px 88px,
        718px 500px;
      background-position:
        3% 30%,
        19% center;
      padding: 12px 9px;
      height: 105px;
      border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
    }
  }

  @media screen and (max-width: 1365px) {
    height: 650px;
  }

  @media screen and (max-width: 1023px) {
    height: 593px;
  }

  @media screen and (max-width: 767px) {
    height: fit-content;
  }
}
